const colors = {
  blue: {
    1000: 'hsla(215, 89%, 2%, 1)',
    900: 'hsla(215, 89%, 8%, 1)',
    800: 'hsla(215, 89%, 14%, 1)', // walmart blue - #041E42
    700: 'hsla(215, 89%, 20%, 1)',
    600: 'hsla(215, 89%, 38%, 1)',
    500: 'hsla(215, 89%, 50%, 1)',
    400: 'hsla(215, 89%, 62%, 1)',
    300: 'hsla(215, 89%, 74%, 1)',
    200: 'hsla(215, 89%, 86%, 1)',
    100: 'hsla(215, 89%, 98%, 1)',
  },
  green: {
    1000: 'hsla(125, 86%, 14%, 1)',
    900: 'hsla(125, 73%, 20%, 1)',
    800: 'hsla(125, 56%, 29%, 1)',
    700: 'hsla(122, 47%, 35%, 1)',
    600: 'hsla(122, 39%, 41%, 1)',
    500: 'hsla(123, 35%, 51%, 1)',
    400: 'hsla(123, 38%, 63%, 1)',
    300: 'hsla(122, 42%, 75%, 1)',
    200: 'hsla(126, 49%, 84%, 1)',
    100: 'hsla(125, 65%, 93%, 1)',
  },
  grey: {
    1000: 'hsla(210, 24%, 16%, 1)',
    900: 'hsla(209, 20%, 25%, 1)',
    800: 'hsla(209, 18%, 30%, 1)',
    700: 'hsla(209, 14%, 37%, 1)',
    600: 'hsla(211, 12%, 43%, 1)',
    500: 'hsla(211, 10%, 53%, 1)',
    400: 'hsla(211, 13%, 65%, 1)',
    300: 'hsla(210, 16%, 82%, 1)',
    200: 'hsla(214, 15%, 91%, 1)',
    100: 'hsla(216, 33%, 97%, 1)',
  },
  orange: {
    1000: 'hsl(25, 84%, 6%, 1)',
    900: 'hsl(25, 84%, 16%, 1)',
    800: 'hsl(25, 84%, 26%, 1)',
    700: 'hsl(25, 84%, 36%, 1)',
    600: 'hsl(25, 84%, 46%, 1)',
    500: 'hsl(25, 84%, 56%, 1)', // walmart orange - #ed8031
    400: 'hsl(25, 84%, 66%, 1)',
    300: 'hsl(25, 84%, 76%, 1)',
    200: 'hsl(25, 84%, 86%, 1)',
    100: 'hsl(25, 84%, 96%, 1)',
  },
  red: {
    1000: 'hsla(360, 92%, 20%, 1)',
    900: 'hsla(360, 85%, 25%, 1)',
    800: 'hsla(360, 79%, 32%, 1)',
    700: 'hsla(360, 72%, 38%, 1)',
    600: 'hsla(360, 67%, 44%, 1)',
    500: 'hsla(360, 64%, 55%, 1)',
    400: 'hsla(360, 71%, 66%, 1)',
    300: 'hsla(360, 77%, 78%, 1)',
    200: 'hsla(360, 82%, 89%, 1)',
    100: 'hsla(360, 100%, 97%, 1)',
  },
  white: '#ffffff',
  black: '#010101',
  yellow: {
    1000: 'hsla(44, 100%, 6%, 1)',
    900: 'hsla(44, 100%, 16%, 1)',
    800: 'hsla(44, 100%, 26%, 1)',
    700: 'hsla(44, 100%, 36%, 1)',
    600: 'hsla(44, 100%, 46%, 1)',
    500: 'hsla(44, 100%, 56%, 1)', // walmart yellow - #ffc220
    400: 'hsla(44, 100%, 66%, 1)',
    300: 'hsla(44, 100%, 76%, 1)',
    200: 'hsla(44, 100%, 86%, 1)',
    100: 'hsla(44, 100%, 96%, 1)',
  },
  trans: {
    grey: 'hsla(210, 24%, 16%, 0.50)', // grey.1000
    ozark: 'hsla(215, 89%, 14%, 0.50)',
  },
  walmart: {
    blue: 'hsla(215, 89%, 14%, 1)',
    lightBlue: 'hsla(199, 100%, 30%, 1)',
    yellow: 'hsla(44, 100%, 56%, 1)', // #ffc220
    orange: 'hsl(25, 84%, 56%, 1)', // #ed8031
  },
  storeNo8: {
    blue: 'hsla(216, 100%, 50%, 1)',
  },
  inHome: {
    softYellow: 'hsla(41, 100%, 75%, 1)',
    blue: 'hsla(209, 100%, 43%, 1)',
    illustrationBlue: 'hsla(207, 99%, 35%, 1)',
    darkBlue: 'hsla(210, 100%, 29%, 1)',
    skyBlue: 'hsla(197, 83%, 75%)',
    backgroundBlue: 'hsla(190, 78%, 84%, 1)',
    homebaseTeal: 'hsla(182, 57%, 47%, 1)',
    homebaseBlue: 'hsla(217, 39%, 47%, 1)',
    highlightGreen: 'hsla(136, 40%, 49%, 1)',
    green: 'hsla(102, 96%, 27%, 1)',
    lennarBlue: 'hsla(207, 100%, 33%, 1)',
    error: 'hsla(358, 78%, 49%, 1)', //#DE1C24 Mr. Sam's pickup
    softRed: '#ea4949',
    softestGrey: 'hsla(0, 0%, 98%, 1)',
    medGrey: 'hsla(180, 4%, 95%, 1)',
    grey: 'hsla(0, 0%, 81%, 1)',
    darkGrey: 'hsla(216, 6%, 46%, 1)',
    lightGrey: '#e5e5e5',
    mlpBackgroundBlue: '#F2F8FD',
  },
};

export default colors;

export const button = {
  // TODO: Make these button types composable.

  disabled: {
    backgroundColor: 'transparent',
    color: colors.inHome.grey,
    borderColor: colors.inHome.lightGrey,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'not-allowed',
  },

  default: {
    backgroundColor: colors.walmart.blue,
    color: colors.white,
    borderColor: colors.walmart.blue,
  },

  defaultInverted: {
    color: colors.walmart.blue,
    borderColor: colors.walmart.blue,
    borderWidth: '1px',
    backgroundColor: 'transparent',
  },

  cancel: {
    backgroundColor: colors.white,
    color: colors.red[800],
    borderColor: colors.red[800],
    borderWidth: '1px',
    cursor: 'pointer',
  },

  small: {
    fontFamily: 'Bogle',
    fontWeight: 'normal',
    borderRadius: '20px',
    fontSize: '14px',
    minHeight: '35px',
    minWidth: '100px',
    cursor: 'pointer',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },

  medium: {
    fontFamily: 'Bogle',
    fontWeight: 'normal',
    borderRadius: '27px',
    fontSize: '16px',
    minHeight: '50px',
    minWidth: '150px',
    cursor: 'pointer',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },

  large: {
    fontFamily: 'Bogle',
    fontWeight: 'normal',
    borderRadius: '32px',
    fontSize: '17px',
    minHeight: '60px',
    minWidth: '200px',
    cursor: 'pointer',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },

  walmartDefault: {
    backgroundColor: colors.walmart.blue,
    color: colors.white,
    borderColor: colors.walmart.blue,
  },

  walmartYellow: {
    backgroundColor: colors.walmart.yellow,
    color: colors.walmart.blue,
    borderColor: colors.walmart.yellow,
  },

  walmartLarge: {
    fontFamily: 'Bogle',
    fontWeight: 'normal',
    borderRadius: '20px',
    borderWidth: '2px',
    borderStyle: 'solid',
    fontSize: '16px',
    height: '40px',
    minWidth: '80px',
    cursor: 'pointer',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
};
