import React, { useContext, useEffect } from 'react';
import queryString from 'query-string';
import LoadingSpinner from 'Components/loadingSpinner';
import { DefaultContext } from 'Context/DefaultContext';

import colors from 'Utils/theme';
import { getCookie } from 'Utils';

const Home = () => {
  const { walmartURL } = useContext(DefaultContext);

  const wPlusCookie = getCookie('plus_params');
  const queryParams = wPlusCookie ? `?${queryString.stringify(JSON.parse(wPlusCookie))}` : ``;

  useEffect(() => {
    window.location.assign(`${walmartURL}/plus/inhome${queryParams}`);
  }, []);

  return (
    <div style={{ width: '100vw', height: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <LoadingSpinner color={colors.inHome.blue} />
    </div>
  );
};

export default Home;
